const SidebarData = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    url: "/dashboard",
    issubMenubadge: true,
    bgcolor: "bg-primary",
    badgeValue: "3",
  },
  {
    label: "Register Driver",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/register-ambulance-driver",
  },
  {
    label: "Driver List",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/driver-list",
  },
  {
    label: "Calling History",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/driver-history",
  },
];
export default SidebarData;
