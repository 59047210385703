import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Pages
import RegisterDriverPage from "../Pages/RegisterDriver/RegisterDriver-Page.js";
import DriverListPage from "../Pages/DriverList/DriverList-Page.js";
import DriverHistoryPage from "../Pages/DriverHistory/DriverHistory-Page.js";
import DriverTrackingHistoryPage from "../Pages/DriverHistory/DriverTackingHistory/DriverTrackingHistory-Page.js";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import UserProfile from "../Pages/Authentication/user-profile";

import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/register-ambulance-driver", component: <RegisterDriverPage /> },
  { path: "/driver-list", component: <DriverListPage /> },
  { path: "/driver-history", component: <DriverHistoryPage /> },
  { path: "/driver-history/:id", component: <DriverTrackingHistoryPage /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Authentication Inner Pages

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
