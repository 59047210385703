import React, { useEffect, memo, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Modal } from "reactstrap";

const BasicTable = () => {
  const [userApiData, setUserApiData] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [mapData, setMapData] = useState({
    lat: 0,
    long: 0,
  });

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  let { id } = useParams();

  // get users data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/ambulance/driver/driverTrackingHistory/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setUserApiData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">User Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div className="edit">
              {new Date(cell.dateTime).toLocaleDateString()}
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Time</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div className="edit">
              {new Date(cell.dateTime).toLocaleTimeString()}
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case "in-progress":
            return <span className="badge bg-info"> {"in-progress"} </span>;
          case "completed":
            return <span className="badge bg-success"> {"completed"} </span>;
          default:
            return <span className="badge bg-info"> {"in-progress"} </span>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                setIsViewModalOpen(!isViewModalOpen);
                setMapData({
                  lat: cell.lat,
                  long: cell.long,
                });
              }}
            >
              <button
                className="btn btn-sm btn-secondary edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                View
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = userApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.fullName,
      phone: data.phoneNo,
      lat: data.lat,
      long: data.long,
      dateTime: data.createdAt,
      status: data.currentStatus,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
      {isViewModalOpen && (
        <LocationView
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          mapData={mapData}
        />
      )}
    </>
  );
};

const LocationView = memo(
  ({ isViewModalOpen, setIsViewModalOpen, mapData }) => {
    return (
      <Modal
        isOpen={isViewModalOpen}
        toggle={() => {
          setIsViewModalOpen(!isViewModalOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Driver Location</h5>
          <button
            type="button"
            onClick={() => {
              setIsViewModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <iframe
              src={`https://www.google.com/maps?q=${mapData.lat},${mapData.long}&hl=es;&output=embed`}
              title="Driver Location"
              width="100%"
              height="400px"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </div>
      </Modal>
    );
  }
);

export { BasicTable };
