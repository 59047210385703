import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const BasicTable = () => {
  const [userApiData, setUserApiData] = useState([]);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  const navigate = useNavigate();

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get users data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/ambulance/driver/allOrg`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setUserApiData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Driver Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">History</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                navigate(`/driver-history/${cell._id}`);
              }}
            >
              <button
                className="btn btn-sm btn-info"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                History
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = userApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
    </>
  );
};

export { BasicTable };
