import React, { useState } from "react";

const Navdata = () => {
  const [pages, setpages] = useState(false);
  const [banner, setBanner] = useState(false);
  const [category, setCategory] = useState(false);
  const [user, setUser] = useState(false);

  const NavnavData = [
    {
      id: 1,
      label: "Dashboard",
      icon: "mdi mdi-home-variant-outline me-2",
      isdropDown: true,
      click: function () {
        setpages(false);
      },
    },
    {
      id: 2,
      label: "Register Driver",
      icon: "mdi mdi-home-variant-outline me-2",
      isdropDown: true,
      click: function () {
        setpages(false);
      },
    },
    {
      id: 5,
      label: "Pages",
      icon: "ri-file-copy-2-line me-2",
      click: function () {
        setpages(!pages);

        setBanner(false);
        setCategory(false);
        setUser(false);
      },
      currentState: pages,
      subItems: [
        {
          label2: "Banner",
          staclick: function () {
            setBanner(!banner);
            setCategory(false);
            setUser(false);
          },
          subState: banner,
          subItem: [
            { link: "/create-banner", title: "Create Banner" },
            { link: "/banner-list", title: "Banner List" },
          ],
        },
        {
          label2: "Category",
          staclick: function () {
            setCategory(!category);
            setBanner(false);
            setUser(false);
          },
          subState: category,
          subItem: [
            { link: "/create-category", title: "Create Category" },
            { link: "/category-list", title: "Category List" },
          ],
        },

        {
          label2: "User",
          staclick: function () {
            setUser(!user);
          },
          subState: user,
          subItem: [
            // { link: "/register-driver", title: "Register Driver" },
            { link: "/user-list", title: "User List" },
          ],
        },
      ],
    },
  ];
  return <React.Fragment>{NavnavData}</React.Fragment>;
};

export default Navdata;
